import { request } from '@faire/web-api--source';
import { ISendMessengerMessageRequestV3 } from '@faire/web-api--source/faire/messenger/ISendMessengerMessageRequestV3';
import { ISendMessengerMessageResponseV3 } from '@faire/web-api--source/faire/messenger/ISendMessengerMessageResponseV3';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v3/messenger/send-message`;
};

export const build = (
  data: ISendMessengerMessageRequestV3,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/send-message';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(
  data: ISendMessengerMessageRequestV3
): Promise<ISendMessengerMessageResponseV3>;
function execute(
  data: ISendMessengerMessageRequestV3,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ISendMessengerMessageResponseV3>>;
function execute(
  data: ISendMessengerMessageRequestV3,
  options?: EndpointOptions
): Promise<ISendMessengerMessageResponseV3>;
function execute(
  data: ISendMessengerMessageRequestV3,
  options?: EndpointOptions
): Promise<
  | ISendMessengerMessageResponseV3
  | SuccessResponse<ISendMessengerMessageResponseV3>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<ISendMessengerMessageResponseV3>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v3/messenger/send-message/post';
export default execute;
