import { getWebApiErrorMessage } from "@faire/web--source/common/getErrorMessage";
import { localDate } from "@faire/web--source/common/localization/localDate";
import {
  backwardsCompatibleValues,
  StrictLocalizeFunction,
} from "@faire/web--source/common/localization/localize";
import { logError } from "@faire/web--source/common/logging";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import deleteMessage from "@faire/web-api--source/endpoints/www/api/messenger/messageToken/delete";
import getCustomer, {
  QueryParameters as IGetCustomerDetailsRequest,
} from "@faire/web-api--source/endpoints/www/api/v3/crm/brandToken/customer_details/get";
import createConversationV3Action from "@faire/web-api--source/endpoints/www/api/v3/messenger/create-conversation/post";
import getMessengerMessagesV3 from "@faire/web-api--source/endpoints/www/api/v3/messenger/list-messages/post";
import sendMessageReadReceiptV3 from "@faire/web-api--source/endpoints/www/api/v3/messenger/read-conversation/post";
import { ICreateConversationRequestV3 } from "@faire/web-api--source/faire/messenger/ICreateConversationRequestV3";
import { IListMessagesRequestV3 } from "@faire/web-api--source/faire/messenger/IListMessagesRequestV3";
import { IMessageContents } from "@faire/web-api--source/faire/messenger/IMessageContents";
import { IMessengerParticipantToken } from "@faire/web-api--source/faire/messenger/IMessengerParticipantToken";
import { IGetCrmV3CustomerDetailsResponse } from "@faire/web-api--source/indigofair/brand/crm/IGetCrmV3CustomerDetailsResponse";
import { IBasicUser } from "@faire/web-api--source/indigofair/data/IBasicUser";
import { IGetMessengerConversationsResponse } from "@faire/web-api--source/indigofair/data/IGetMessengerConversationsResponse";
import { IMessengerConversation } from "@faire/web-api--source/indigofair/data/IMessengerConversation";
import { IMessengerConversationParticipant } from "@faire/web-api--source/indigofair/data/IMessengerConversationParticipant";
import { IMessengerMessage } from "@faire/web-api--source/indigofair/data/IMessengerMessage";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";
import { MessengerConversationType } from "@faire/web-api--source/indigofair/data/MessengerConversationType";
import { UserRole } from "@faire/web-api--source/indigofair/data/UserRole";
import { startOfDay } from "date-fns/startOfDay";
import isEmpty from "lodash/isEmpty";
import max from "lodash/max";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
} from "mobx";

import {
  SYNTHETIC_BRAND_VACATION_TOKEN,
  SYNTHETIC_OTHER_PARTY_STATUS_TOKEN,
  SYNTHETIC_SUPPORT_MESSAGE,
  SYNTHETIC_SUPPORT_TOKEN,
} from "./constants";
import { MessageModel } from "./MessageModel";
import { MessengerStore } from "./MessengerStore";
import { throttlePromise } from "./throttlePromise";
import { getBrandTokenInSession } from "./utils";

const isOnVacation = (
  brand?: IGetMessengerConversationsResponse.ISimpleBrand
): boolean => {
  const now = Date.now();

  return (
    !!brand &&
    !!brand.vacation_start_date &&
    !!brand.vacation_end_date &&
    brand.vacation_start_date <= now &&
    brand.vacation_end_date >= now
  );
};

export class ConversationModel {
  otherParty?: IUser;

  @observable
  isOtherPartyBrandCustomer: boolean = false;

  @observable
  private data!: IMessengerConversation;
  @observable
  private messages: Map<string, MessageModel> = new Map();
  private user: IUser;

  @observable
  messageAuthors: Array<IBasicUser> = [];

  @observable
  private brandCustomerResponse: IGetCrmV3CustomerDetailsResponse | undefined;

  @observable
  private _otherPartyCompany?: string;

  constructor(
    private messengerStore: MessengerStore,
    conversationData: Partial<IMessengerConversation>,
    private strictLocalize: StrictLocalizeFunction,
    user?: IUser,
    public brand?: IGetMessengerConversationsResponse.ISimpleBrand,
    public retailer?: IGetMessengerConversationsResponse.ISimpleRetailer
  ) {
    makeObservable(this);
    if (user) {
      this.user = user;
    } else {
      throw new Error(
        "By the time a conversation can be created, there should be a user - Ian"
      );
    }

    this.updateConversation(conversationData);

    // create synthetic events
    this.createSyntheticBrandVacationMessage();
    this.syntheticSupportMessageDisposer = reaction(
      () => this.data.type as MessengerConversationType,
      this.createSyntheticSupportMessage,
      { fireImmediately: true }
    );
    this.syntheticStatusMessageDisposer = reaction(
      () => this.data.other_party_status,
      this.createSyntheticStatusMessage,
      { fireImmediately: true }
    );
  }

  private syntheticSupportMessageDisposer: IReactionDisposer;
  private syntheticStatusMessageDisposer: IReactionDisposer;
  destroy = () => {
    this.syntheticSupportMessageDisposer();
    this.syntheticStatusMessageDisposer();
  };

  @action
  updateConversation = (
    conversationData: Partial<IMessengerConversation>,
    simple_brand?: IGetMessengerConversationsResponse.ISimpleBrand,
    simple_retailer?: IGetMessengerConversationsResponse.ISimpleRetailer
  ) => {
    if (isEmpty(conversationData)) {
      return;
    }
    this.data = {
      ...this.data,
      ...conversationData,
    };

    const otherParty = conversationData.other_party;
    delete this.data.other_party;
    if (otherParty && otherParty.token) {
      this.otherParty = IUser.build(otherParty);
    } else {
      throw new Error("a conversation user should always be valid - Ian");
    }

    if (simple_brand) {
      this.brand = simple_brand;
    }
    if (simple_retailer) {
      this.retailer = simple_retailer;
    }

    if (conversationData.other_party_company) {
      this._otherPartyCompany = conversationData.other_party_company;
    }

    if (conversationData.latest_message) {
      this.createOrUpdateMessage(conversationData.latest_message);
    }
  };

  /*
   * Getters
   */

  get token(): string {
    return this.data.token!;
  }

  get userToken(): string {
    return this.user.token!;
  }

  get type(): MessengerConversationType {
    return this.data.type as MessengerConversationType;
  }

  get lastReadByCurrentUser(): number | undefined {
    return this.data.last_read_at;
  }

  get createdAt(): number | undefined {
    return this.data.created_at;
  }

  // 2022/06/24 Terence Dickson
  // As of the time of writing, this field is only used for getting the company
  // name of a guest user (for prospective retailer messaging, see FD-79918).
  // See `getOtherPartyCompanyName` in `@messenger/store/utils`.
  get otherPartyCompany(): string | undefined {
    return this._otherPartyCompany;
  }

  get otherPartyPic() {
    if (
      this.otherParty &&
      this.isOtherPartyBrandUser &&
      this.brand &&
      this.brand.profile_image
    ) {
      return this.brand.profile_image;
    }

    return this.otherParty?.profile_image;
  }

  get otherPartyBusinessImage() {
    return this.data.other_party_business_image;
  }

  get otherPartyProfilePicUrl() {
    return this.otherPartyPic?.url;
  }

  get isCurrentUserARetailer(): boolean {
    return this.user.roles.includes(UserRole.RETAILER);
  }

  get isCurrentUserABrand(): boolean {
    return this.user.roles.includes(UserRole.MAKER);
  }

  get userProfilePhotoUrl() {
    if (!this.user.profile_image) {
      return undefined;
    }

    if (
      this.isCurrentUserBrandUser &&
      this.messengerStore.brand &&
      this.messengerStore.brand.profile_image
    ) {
      return this.messengerStore.brand.profile_image.url;
    }

    return this.user.profile_image.url;
  }

  get participants(): IMessengerConversationParticipant[] {
    return this.data.participants;
  }

  /**
   * The creation date of the latest message or the conversation whichever is closest
   */
  @computed
  get lastModified(): number | undefined {
    return max([
      this.latestMessage && this.latestMessage.createdAt,
      this.data.created_at,
    ]);
  }

  @computed
  get latestMessage(): MessageModel | undefined {
    return this.orderedMessages.find((message) => !message.isDeleted);
  }

  @computed
  get latestReadMessageFromCurrentUser(): MessageModel | undefined {
    for (const message of this.orderedMessages) {
      if (message.isFromCurrentUser && message.isRead) {
        return message;
      }
    }
    return;
  }

  get otherPartyToken(): string {
    return this.otherParty?.token ?? "";
  }

  get isCurrentUserBrandUser(): boolean {
    return this.user.type === IUser.Type.BRAND_USER;
  }

  get isOtherPartyBrandUser(): boolean {
    return this.otherParty?.type === IUser.Type.BRAND_USER;
  }

  get otherPartyBrandToken(): string {
    return this.isOtherPartyBrandUser ? (this.brand?.token ?? "") : "";
  }

  @computed
  get messageCount(): number {
    return this.messages.size;
  }

  @computed
  get isSupportConversation(): boolean {
    return this.data.type === MessengerConversationType.SUPPORT;
  }

  @computed
  get isUnRead(): boolean {
    return (this.data.unread_messages || 0) > 0;
  }

  @computed
  get isStarredByBrand(): boolean {
    return this.data.is_starred_by_brand ?? false;
  }

  @action setIsStarredByBrand = (isStarred: boolean) => {
    this.data.is_starred_by_brand = isStarred;
  };

  @computed
  get existsNextRequest(): boolean {
    return !!this.nextRequest;
  }

  /**
   * Messages are displayed backwards so that infinite scrolling works with flex
   */
  @computed
  get orderedMessages() {
    return sortBy(Array.from(this.messages.values()), [
      (m: MessageModel) =>
        m.createdAt !== undefined ? -m.createdAt : undefined,
    ]);
  }

  @computed
  get showContactSupportMessage(): boolean {
    return (
      !this.messages.size && !this.isLoading && !this.isSupportConversation
    );
  }

  @computed
  get isMarkedAsSpam(): boolean {
    return !!this.data.marked_as_spam;
  }

  @computed
  get inputDisabled(): boolean {
    return this.disabled;
  }

  @computed
  get disabled(): boolean {
    // TODO(thomasb): we assume that if other_party_status is set on the support
    // conversation, then chatting with support is disabled. This should be a separate
    // flag on the message object or controlled directly by a setting.
    return this.isSupportConversation && !!this.data.other_party_status;
  }

  @computed
  get conversationKey(): string | undefined {
    return this.otherParty?.[
      this.user?.type === IUser.Type.BRAND_USER
        ? "retailer_token"
        : "brand_token"
    ];
  }

  /*
   * Async
   */

  openConversation = () => {
    this.firstFetchOrUpdateMessages();
    return this.markAsRead();
  };

  private markAsRead = async (): Promise<void> => {
    if (!this.token) {
      return;
    }
    const response = await sendMessageReadReceiptV3({
      conversation_token: this.token,
    });

    this.messengerStore.setTotalUnreadConversations(
      response.total_unread_conversations
    );
    if (response.conversation) {
      this.updateConversation(response.conversation);
    }
  };

  /**
   * Brand customer for the retailer of the conversation.
   * note: Must call `getBrandCustomer` before calling this method.
   */
  @computed
  get brandCustomer() {
    return this.brandCustomerResponse;
  }

  @computed
  get otherPartyUserType(): IUser.Type {
    return IUser.Type[this.otherParty?.type ?? IUser.Type.UNKNOWN];
  }

  createConversation = async (
    messageContents: IMessageContents
  ): Promise<IMessengerConversation | undefined> => {
    if (!this.brand && !this.retailer) {
      return;
    }

    const other_token = IMessengerParticipantToken.build({
      ...(this.user?.type === IUser.Type.BRAND_USER && {
        retailer_token: this.retailer?.token,
      }),
      ...(this.user?.type === IUser.Type.USER && {
        brand_token: this.brand?.token,
      }),
    });

    const response = await createConversationV3Action(
      ICreateConversationRequestV3.build({
        other_participant_token: other_token,
        message_contents: messageContents,
      })
    );

    if (!response.conversation) {
      logError(new Error("This request should return a new conversation"));
      return;
    }

    this.updateConversation(response.conversation);

    return response.conversation;
  };

  getBrandCustomer = async (): Promise<void> => {
    const brandToken = getBrandTokenInSession();
    if (
      brandToken !== undefined &&
      this.otherParty?.retailer_token !== undefined
    ) {
      try {
        this.brandCustomerResponse = await getCustomer(
          brandToken,
          IGetCustomerDetailsRequest.build({
            retailer_token: this.otherParty.retailer_token,
          })
        );
        this.isOtherPartyBrandCustomer = true;
      } catch (error) {
        this.isOtherPartyBrandCustomer = false;
      }
    } else {
      this.isOtherPartyBrandCustomer = false;
    }
  };
  isFirstFetch = true;
  /**
   * When conversation mounts for the first time this populates messages and sets nextRequest for scroll
   * Future calls makes sure all messages are up to date
   */
  firstFetchOrUpdateMessages = () => {
    if (this.isFirstFetch) {
      this.isFirstFetch = false;

      // Fetch messages for the first time and then fetch again immediately. The
      // backend only returns 20 messages each call so we need to call it twice
      // to initialize with a reasonable number of messages. Use the throttled
      // version so we don't conflict with any other requests to fetch
      // additional messages
      this.fetchMessages(undefined, true).then(() =>
        this.fetchAdditionalMessagesThrottled()
      );
    } else {
      // TODO: When we return after a while we want to fetch and get all messages since the last time we saw them
      //   fetch and keep fetching using nextRequest, until a known good message is reached
      //   currently we assume no more than 50 messages will be new
      this.fetchMessages({ max_results: 50 });
    }
  };

  private nextRequest?: IListMessagesRequestV3;

  fetchAdditionalMessagesThrottled = throttlePromise(async () => {
    if (this.nextRequest) {
      await this.fetchMessages(this.nextRequest, true);
    }
  });

  @observable
  private _isLoading: boolean = false;
  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * handles 2 types of fetches
   * - main stream (first fetch, set nextRequest, follow on using nextRequest)
   * - update data (doesn't keep ordering or nextRequest)
   */
  fetchMessages = async (
    request: Partial<IListMessagesRequestV3> = {},
    isMainStreamFetch?: boolean
  ): Promise<void> => {
    if (this.token) {
      this._isLoading = true;

      try {
        const response = await getMessengerMessagesV3(
          IListMessagesRequestV3.build({
            ...request,
            conversation_token: this.token,
          })
        );

        if (response.messages) {
          this.messageAuthors = uniqBy(
            [...this.messageAuthors, ...response.message_authors],
            (messageAuthor) => messageAuthor.token
          );
          this.createOrUpdateMessages(response.messages);
        }
        if (isMainStreamFetch) {
          this.nextRequest = response.next_request;
        }
      } catch (e) {
        // TODO: in the existing implementation fetch errors are ignored
        logError(e);
      } finally {
        this._isLoading = false;
      }
    }
  };

  /*
      This function does not appear to be used.
      Messages are created outside of the ConversationModel and sent.
      Can this be deleted?
    */
  sendNewMessage = async (
    newMessage: Pick<IMessengerMessage, "text" | "images">
  ): Promise<void> => {
    const message = MessageModel.createNewMessage(this, newMessage, undefined);
    await message.sendMessage();
    // wait until message has been updated with a token from sendMessage
    this.messages.set(message.token, message);
  };

  /**
   * @deprecated upload images separately and use sendNewMessage
   */
  uploadImagesAndSendNewMessage = async (
    newMessage: Pick<IMessengerMessage, "text" | "images">,
    images?: readonly File[],
    products?: string[]
  ): Promise<void> => {
    const message = MessageModel.createNewMessage(
      this,
      newMessage,
      images,
      products
    );
    await message.sendMessage();
    // wait until message has been updated with a token from sendMessage
    this.messages.set(message.token, message);
  };

  @action private createOrUpdateMessages = (messages: IMessengerMessage[]) => {
    messages.forEach((message) => this.createOrUpdateMessage(message));
  };

  @action
  private createOrUpdateMessage = (messageData: Partial<IMessengerMessage>) => {
    if (!messageData.token) {
      throw new Error("message should have a token by now - Ian");
    }

    const message = this.messages.get(messageData.token);

    if (message) {
      message.updateMessage(messageData, undefined, messageData.product_tokens);
    } else {
      this.messages.set(
        messageData.token,
        new MessageModel(
          this,
          messageData,
          undefined,
          messageData.product_tokens
        )
      );
    }
  };

  deleteMessage = async (messageToken: string) => {
    const message = this.messages.get(messageToken);
    if (message) {
      message.setIsDeleting(true);
      try {
        await deleteMessage(messageToken);
      } catch (e) {
        message.setIsDeleting(false);
        message.setError(getWebApiErrorMessage(e, this.strictLocalize));
        return;
      }
      message.setIsDeleting(false);
      message.updateMessage(
        {
          created_at: message.createdAt,
          author_token: this.userToken,
          deleted: true,
        },
        undefined,
        undefined
      );
    }
  };

  /**
   * Synthetic Messages
   * These events show up in a specific order within the message stream
   * I have mixed feelings about if they should be here or in some kind of display function
   */
  createSyntheticMessage = (messageData: Partial<IMessengerMessage>) => {
    this.createOrUpdateMessage({
      author_token: this.otherPartyToken,
      read_at: Date.now(),
      ...messageData,
    });
  };

  private createSyntheticBrandVacationMessage = () => {
    // TEST(Ian): make sure this is the latest message
    if (this.brand && isOnVacation(this.brand)) {
      this.createSyntheticMessage({
        token: SYNTHETIC_BRAND_VACATION_TOKEN,
        text: this.strictLocalize(
          {
            defaultMessage: "We're on pause until {date}",
            description: {
              text: "Message indicating that the brand is on vacation and will return on a certain date",
            },
          },
          backwardsCompatibleValues({
            date: localDate(this.brand.vacation_end_date || new Date(), "PP"),
          })
        ),
        created_at: this.brand.vacation_start_date,
        images: [],
      });
    }
  };

  private createSyntheticSupportMessage = (
    type: MessengerConversationType | undefined
  ) => {
    // TEST(Ian): make sure this is the oldest message
    if (type === "SUPPORT") {
      this.createSyntheticMessage({
        token: SYNTHETIC_SUPPORT_TOKEN,
        text: SYNTHETIC_SUPPORT_MESSAGE(this.strictLocalize),
        images: [],
      });
    } else if (this.messages.has(SYNTHETIC_SUPPORT_TOKEN)) {
      this.messages.delete(SYNTHETIC_SUPPORT_TOKEN);
    }
  };

  private createSyntheticStatusMessage = (
    otherPartyStatus: string | undefined
  ) => {
    // TEST(Ian): make sure this is constant per day
    if (otherPartyStatus) {
      this.createSyntheticMessage({
        token: SYNTHETIC_OTHER_PARTY_STATUS_TOKEN,
        text: this.data.other_party_status,
        created_at: startOfDay(Date.now()).getTime(),
        images: [],
      });
    } else if (this.messages.has(SYNTHETIC_OTHER_PARTY_STATUS_TOKEN)) {
      this.messages.delete(SYNTHETIC_OTHER_PARTY_STATUS_TOKEN);
    }
  };

  clearMessages = () => {
    this.messages.clear();
  };
}
