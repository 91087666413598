import { trackClientEvent } from '@faire/web-api--source/trackClientEvent';
import { ITraceEventMarker } from '@faire/web--source/common/pete/TraceEventTracker';

export const eventKey =
  'messenger-message_from_brand_to_retailer-action_unknown';

/**
 * Capture "messages received by retailers”. It includes:
- Retailer ID or token for the retailer receiving the message*
- Brand ID or token for who sent the message
- Time the message was received*
 */
export function trackMessengerMessageFromBrandToRetailer(
  retailerToken: string,
  brandToken: string,
  received_at: string,
  additional2: string
): ITraceEventMarker {
  return trackClientEvent({
    eventKey,
    realTimeTracking: false,
    parameters: { retailerToken, brandToken, received_at, additional2 },
  });
}
