import { getDocument } from "@faire/web--source/common/globals/getDocument";
import { getWindow } from "@faire/web--source/common/globals/getWindow";
import getMostRecentMessage from "@faire/web-api--source/endpoints/www/api/messenger/most-recent-message/get";

export class MessengerPollingFallback {
  static readonly FALLBACK_INTERVAL = 15 * 1000;

  // Randomize window before starting to poll, to avoid rhythmically attacking the server
  static readonly WINDOW_BEFORE_START = 300 * 1000 + Math.random() * 30 * 1000;

  private readonly MAX_DELAY_MULTIPLIER = 16;

  private timeoutId: number | undefined;

  private lastTimestamp: number | undefined;
  private delayMultiplier: number = 1;

  constructor(private onNewMessage: () => void, disconnected: boolean) {
    // If we were disconnected, don't call updateTimestamp immediately as it's common
    // for the polling method to kick in for a very short period of time before the
    // websocket comes back. Give the websocket a chance before starting to poll.
    this.timeoutId = getWindow()?.setTimeout(
      () => {
        this.updateTimestamp();
      },
      // First poll will occur either after FALLBACK_INTERVAL (not disconnected) or
      // FALLBACK_INTERVAL + WINDOW_BEFORE_START (disconnected)
      disconnected
        ? MessengerPollingFallback.WINDOW_BEFORE_START +
            MessengerPollingFallback.FALLBACK_INTERVAL
        : MessengerPollingFallback.FALLBACK_INTERVAL
    );
    getDocument()?.addEventListener("focus", () => {
      this.delayMultiplier = 1;
      this.resetTimer();
    });
  }

  stop = () => {
    getWindow()?.clearTimeout(this.timeoutId);
  };

  private resetTimer = () => {
    this.stop();
    this.timeoutId = getWindow()?.setTimeout(
      () => {
        this.updateTimestamp();
      },
      // exponentially delay each next request (max 4 mins)
      this.delayMultiplier * MessengerPollingFallback.FALLBACK_INTERVAL
    );
  };
  private updateTimestamp = () => {
    if (!getDocument()?.hasFocus()) {
      // Exit early if window does not have focus. We don't want to spam the most-recent-message endpoint when a user
      // has a bunch of tabs open.
      this.delayMultiplier *= 2;
      this.delayMultiplier = Math.min(
        this.delayMultiplier,
        this.MAX_DELAY_MULTIPLIER
      );
      this.resetTimer();
    } else {
      getMostRecentMessage()
        .then((response) => {
          if (response.timestamp !== this.lastTimestamp) {
            // Note this will fire once on the first interval as we don't know
            // if something is new or not.
            this.onNewMessage();
            this.lastTimestamp = response.timestamp;
            this.delayMultiplier = 1;
          } else {
            this.delayMultiplier *= 2;
            this.delayMultiplier = Math.min(
              this.delayMultiplier,
              this.MAX_DELAY_MULTIPLIER
            );
          }
        })
        .finally(this.resetTimer);
    }
  };
}
