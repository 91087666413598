import { request } from '@faire/web-api--source';
import { ICreateConversationRequestV3 } from '@faire/web-api--source/faire/messenger/ICreateConversationRequestV3';
import { ICreateConversationResponseV3 } from '@faire/web-api--source/faire/messenger/ICreateConversationResponseV3';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v3/messenger/create-conversation`;
};

export const build = (
  data: ICreateConversationRequestV3,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/create-conversation';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(
  data: ICreateConversationRequestV3
): Promise<ICreateConversationResponseV3>;
function execute(
  data: ICreateConversationRequestV3,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<ICreateConversationResponseV3>>;
function execute(
  data: ICreateConversationRequestV3,
  options?: EndpointOptions
): Promise<ICreateConversationResponseV3>;
function execute(
  data: ICreateConversationRequestV3,
  options?: EndpointOptions
): Promise<
  ICreateConversationResponseV3 | SuccessResponse<ICreateConversationResponseV3>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<ICreateConversationResponseV3>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v3/messenger/create-conversation/post';
export default execute;
