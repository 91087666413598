import { request } from '@faire/web-api--source';
import { IGetConversationResponseV3 } from '@faire/web-api--source/faire/messenger/IGetConversationResponseV3';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = (retailerToken: string) => {
  return `/api/v3/messenger/retailer-conversation/${retailerToken}`;
};

export const build = (
  retailerToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(retailerToken),
    method: 'GET',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/retailer-conversation/:retailer_token';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(retailerToken: string): Promise<IGetConversationResponseV3>;
function execute(
  retailerToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetConversationResponseV3>>;
function execute(
  retailerToken: string,
  options?: EndpointOptions
): Promise<IGetConversationResponseV3>;
function execute(
  retailerToken: string,
  options?: EndpointOptions
): Promise<
  IGetConversationResponseV3 | SuccessResponse<IGetConversationResponseV3>
> {
  const { route, ...requestOptions } = build(retailerToken, options);
  return request<IGetConversationResponseV3>(requestOptions);
}
execute[PATH] =
  'endpoints/www/api/v3/messenger/retailer-conversation/retailerToken/get';
export default execute;
