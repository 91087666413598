import { UserStore } from "@faire/retailer-visitor-shared/stores/domain/UserStore";
import { singletonGetter } from "@faire/web--source/common/singletons/getter";
import { mockStrictLocalize } from "@faire/web--source/common/testing/unit/mockStrictLocalize";
import { MessengerStore } from "@faire/web--source/messenger/store/MessengerStore";
import { createStoreHook } from "@faire/web--source/ui/hooks/useStore";

/**
 * Factory methods to get access to messenger related classes.
 * @deprecated prefer to use `useMessengerFactory` or `withStores` instead
 */
export class MessengerFactory {
  static get = singletonGetter(MessengerFactory);

  private _messengerStore: MessengerStore | undefined;

  set messengerStore(messengerStore: MessengerStore) {
    this._messengerStore = messengerStore;
  }

  get messengerStore() {
    return (
      this._messengerStore ||
      new MessengerStore(UserStore.get().user, mockStrictLocalize)
    );
  }
}

export const useMessengerFactory = createStoreHook(MessengerFactory);
