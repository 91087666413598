import { request } from '@faire/web-api--source';
import { IMessengerConversation } from '@faire/web-api--source/indigofair/data/IMessengerConversation';
import { IStarUnstarConversationRequest } from '@faire/web-api--source/indigofair/data/IStarUnstarConversationRequest';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/messenger/star-unstar-conversation`;
};

export const build = (
  data: IStarUnstarConversationRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messenger/star-unstar-conversation';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(
  data: IStarUnstarConversationRequest
): Promise<IMessengerConversation>;
function execute(
  data: IStarUnstarConversationRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IMessengerConversation>>;
function execute(
  data: IStarUnstarConversationRequest,
  options?: EndpointOptions
): Promise<IMessengerConversation>;
function execute(
  data: IStarUnstarConversationRequest,
  options?: EndpointOptions
): Promise<IMessengerConversation | SuccessResponse<IMessengerConversation>> {
  const { route, ...requestOptions } = build(data, options);
  return request<IMessengerConversation>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/messenger/star-unstar-conversation/post';
export default execute;
