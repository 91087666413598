import { request } from '@faire/web-api--source';
import { IReadConversationRequestV3 } from '@faire/web-api--source/faire/messenger/IReadConversationRequestV3';
import { IReadConversationResponseV3 } from '@faire/web-api--source/faire/messenger/IReadConversationResponseV3';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v3/messenger/read-conversation`;
};

export const build = (
  data: IReadConversationRequestV3,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v3/messenger/read-conversation';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(
  data: IReadConversationRequestV3
): Promise<IReadConversationResponseV3>;
function execute(
  data: IReadConversationRequestV3,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IReadConversationResponseV3>>;
function execute(
  data: IReadConversationRequestV3,
  options?: EndpointOptions
): Promise<IReadConversationResponseV3>;
function execute(
  data: IReadConversationRequestV3,
  options?: EndpointOptions
): Promise<
  IReadConversationResponseV3 | SuccessResponse<IReadConversationResponseV3>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IReadConversationResponseV3>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v3/messenger/read-conversation/post';
export default execute;
