/* eslint-disable complexity */
import { StrictLocalizeFunction } from "@faire/web--source/common/localization";
import { logError } from "@faire/web--source/common/logging";
import { makeObservable } from "@faire/web--source/common/makeObservable";
import findMessengerConversations from "@faire/web-api--source/endpoints/www/api/messenger/list-conversations/post";
import postStarConversation from "@faire/web-api--source/endpoints/www/api/messenger/star-unstar-conversation/post";
import fetchMessengerConversationByBrand from "@faire/web-api--source/endpoints/www/api/v3/messenger/brand-conversation/brandToken/get";
import fetchMessengerConversation from "@faire/web-api--source/endpoints/www/api/v3/messenger/conversation/conversationToken/get";
import fetchMessengerConversationByRetailer from "@faire/web-api--source/endpoints/www/api/v3/messenger/retailer-conversation/retailerToken/get";
import { trackMessengerMessageFromBrandToRetailer } from "@faire/web-api--source/events/messenger/actionUnknown/messageFromBrandToRetailer";
import { trackMessengerMessageFromRetailerToBrand } from "@faire/web-api--source/events/messenger/actionUnknown/messageFromRetailerToBrand";
import { IGetConversationResponseV3 } from "@faire/web-api--source/faire/messenger/IGetConversationResponseV3";
import { IGetMessengerConversationsRequest } from "@faire/web-api--source/indigofair/data/IGetMessengerConversationsRequest";
import { IGetMessengerConversationsResponse } from "@faire/web-api--source/indigofair/data/IGetMessengerConversationsResponse";
import { IMessengerConversation } from "@faire/web-api--source/indigofair/data/IMessengerConversation";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";
import { IUser } from "@faire/web-api--source/indigofair/data/IUser";
import { MessengerConversationType } from "@faire/web-api--source/indigofair/data/MessengerConversationType";
import { IWebSocketMessage } from "@faire/web-api--source/indigofair/websockets/IWebSocketMessage";
import debug from "debug";
import compact from "lodash/compact";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import { action, computed, observable, reaction, runInAction } from "mobx";

import { IMessageChannel, Message } from "../MessageChannel/MessageChannel";

import { ConversationModel } from "./ConversationModel";
import { MessengerPollingFallback } from "./MessengerPollingFallback";
import { throttlePromise } from "./throttlePromise";
import {
  conversationBrandContextComparator,
  conversationLastModifiedComparator,
  conversationRetailerContextComparator,
  getBrandTokenInSession,
} from "./utils";

const logDebug = debug("IF:MESSENGER");

export const FILTER_SUPPORT_CONVERSATION = true;

class MainStreamFetchState {
  @observable
  isFirstFetch: boolean = true;

  @observable
  nextRequest?: IGetMessengerConversationsRequest;

  /**
   * HACK!!! These are used to compare previous and current responses on the main stream so we know if we are done fetching
   */
  @observable
  lastMainStreamConversationRequestTokens?: (string | undefined)[];

  @observable
  conversationByConversationToken: Map<string, ConversationModel> = new Map();

  // TODO: This should be removed once API v3 is stable (as we'll rely on brand/retailer tokens)
  @observable
  conversationByUserToken: Map<string, ConversationModel> = new Map();

  @observable
  conversationByBrandToken: Map<string, ConversationModel> = new Map();

  @observable
  conversationByRetailerToken: Map<string, ConversationModel> = new Map();

  constructor() {
    makeObservable(this);
  }

  removeReadConversations = () => {
    this.conversationByUserToken.forEach((conversation, userToken) => {
      if (!conversation.isUnRead) {
        this.conversationByUserToken.delete(userToken);
      }
    });

    this.conversationByBrandToken.forEach((conversation, brandToken) => {
      if (!conversation.isUnRead) {
        this.conversationByBrandToken.delete(brandToken);
      }
    });

    this.conversationByRetailerToken.forEach((conversation, retailerToken) => {
      if (!conversation.isUnRead) {
        this.conversationByRetailerToken.delete(retailerToken);
      }
    });

    this.conversationByConversationToken.forEach(
      (conversation, conversationToken) => {
        if (!conversation.isUnRead) {
          this.conversationByConversationToken.delete(conversationToken);
        }
      }
    );
  };

  removeNonStarredByBrandConversations = () => {
    this.conversationByUserToken.forEach((conversation, userToken) => {
      if (!conversation.isStarredByBrand) {
        this.conversationByUserToken.delete(userToken);
      }
    });

    this.conversationByBrandToken.forEach((conversation, brandToken) => {
      if (!conversation.isStarredByBrand) {
        this.conversationByBrandToken.delete(brandToken);
      }
    });

    this.conversationByRetailerToken.forEach((conversation, retailerToken) => {
      if (!conversation.isStarredByBrand) {
        this.conversationByRetailerToken.delete(retailerToken);
      }
    });

    this.conversationByConversationToken.forEach(
      (conversation, conversationToken) => {
        if (!conversation.isStarredByBrand) {
          this.conversationByConversationToken.delete(conversationToken);
        }
      }
    );
  };
}

export class MessengerStore {
  private user?: IUser;

  private brandContext?: IGetMessengerConversationsResponse.ISimpleBrand;

  private latestMessageUpdatedAt?: number;

  @observable
  private retailerContext?: IRetailer;

  @observable
  private _query?: string;
  get query(): string {
    return this._query ?? "";
  }

  private lastSearchQuery?: string;

  @observable
  private searchResults?: Set<string>;

  // In most cases, it makes more sense to use this.mainStreamFetchState instead
  // of these variables. this.mainStreamFetchState automatically selects the
  // state associated with the currently visible list of conversations (which
  // changes depending on the value of showOnlyUnreadConversations).
  @observable
  private allMainStreamFetchState = new MainStreamFetchState();
  @observable
  private unreadOnlyMainStreamFetchState = new MainStreamFetchState();

  // This and related code will be used for FD-124518
  @observable
  private starredByBrandOnlyMainStreamFetchState = new MainStreamFetchState();

  @computed
  private get mainStreamFetchState() {
    if (this.showOnlyUnreadConversations) {
      return this.unreadOnlyMainStreamFetchState;
    } else if (this.showOnlyStarredByBrandConversations) {
      return this.starredByBrandOnlyMainStreamFetchState;
    }
    return this.allMainStreamFetchState;
  }

  @computed
  get conversationByUserToken() {
    return this.mainStreamFetchState.conversationByUserToken;
  }

  @computed
  get conversationByBrandToken() {
    return this.mainStreamFetchState.conversationByBrandToken;
  }

  @computed
  get conversationByRetailerToken() {
    return this.mainStreamFetchState.conversationByRetailerToken;
  }

  @computed
  get conversationByConversationToken() {
    return this.mainStreamFetchState.conversationByConversationToken;
  }

  @observable
  private _currentConversation?: ConversationModel;

  @computed
  get currentConversation(): ConversationModel | undefined {
    return this._currentConversation;
  }

  @observable
  private _isLoadingCount: number = 0;

  private pollingFallback?: MessengerPollingFallback;

  private wasConnected: boolean = false;

  @observable
  _showOnlyUnreadConversations: boolean = false;

  @observable
  _showOnlyStarredByBrandConversations: boolean = false;

  @observable
  private hiddenConversations: string[] = [];

  constructor(
    user: IUser | undefined,
    private strictLocalize: StrictLocalizeFunction,
    public brand?: IGetMessengerConversationsResponse.ISimpleBrand
  ) {
    makeObservable(this);
    this.user = user;

    // No disposing of the reaction because we assume instances of this class
    // live forever.
    reaction(
      () => [this._query],
      () => {
        if (this._query !== this.lastSearchQuery) {
          this.searchConversations({
            query: this._query,
            context_token:
              this.brandContext?.token ?? this.retailerContext?.token,
          });
        }
      },
      { delay: 200 }
    );
  }

  init = async (user: IUser, messageChannel: IMessageChannel | undefined) => {
    this.user = user;

    messageChannel?.subscribe(this.handleNewMessage);

    await this.firstFetchOrUpdateConversations();
  };

  get isLoadingConversations(): boolean {
    return this._isLoadingCount > 0;
  }

  getConversationByUser = (userToken: string): ConversationModel | undefined =>
    this.conversationByUserToken.get(userToken);

  getConversationByBrand = (
    brandToken: string
  ): ConversationModel | undefined =>
    this.conversationByBrandToken.get(brandToken);

  getConversationByRetailer = (
    retailerToken: string
  ): ConversationModel | undefined =>
    this.conversationByRetailerToken.get(retailerToken);

  getConversationByConversationToken = (
    conversationToken: string
  ): ConversationModel | undefined =>
    this.conversationByConversationToken.get(conversationToken);

  /**
   * Sets the conversation as the first conversation in the list.
   *
   * Useful when you're on a page that refers to this conversation
   * ie. brand page, contact, brand order
   */
  @action setCurrentBrandContext = async (
    brand?: IGetMessengerConversationsResponse.ISimpleBrand
  ) => {
    if (get(brand, "token") === get(this.brandContext, "token")) {
      return;
    }

    this._query = undefined;

    // If we have a brand context that we're switching from, see if the
    // conversation has no messages. If so, remove it.
    // Don't check conversationByConversationToken because a conversation
    // has to have messages to have a conversation_token
    if (this.brandContext) {
      const conversation = this.conversationByBrandToken.get(
        this.brandContext!.token!
      );
      if (conversation && conversation.messageCount === 0) {
        this.conversationByUserToken.delete(
          this.brandContext!.owner_user_token!
        );
        this.conversationByBrandToken.delete(this.brandContext!.token!);
      }
    }

    if (brand && !brand.hide_chat) {
      this.brandContext = brand;
    } else {
      this.brandContext = undefined;
    }

    if (this.brandContext) {
      await this.fetchConversations({
        context_token: this.brandContext.token,
      });
    }
  };

  /**
   * Sets the conversation as the first conversation in the list.
   *
   * Useful when you're on a page that refers to this conversation
   * ie. brand order fullfilment
   */
  @action updateCurrentRetailerContext = async (retailer?: IRetailer) => {
    if (get(retailer, "token") === get(this.retailerContext, "token")) {
      return;
    }

    this._query = undefined;

    // If we have a brand context that we're switching from, see if the
    // conversation has no messages. If so, remove it.
    // Don't check conversationByConversationToken because a conversation
    // has to have messages to have a conversation_token
    if (this.retailerContext?.token && this.retailerContext.owner_user_token) {
      const conversation = this.conversationByRetailerToken.get(
        this.retailerContext.token
      );
      if (conversation?.messageCount === 0) {
        this.conversationByUserToken.delete(
          this.retailerContext.owner_user_token
        );
        this.conversationByRetailerToken.delete(this.retailerContext.token);
      }
    }

    this.retailerContext = retailer;
    await this.fetchConversations({
      context_token: this.retailerContext?.token,
    });
  };

  @computed
  get currentRetailerContext(): IRetailer | undefined {
    return this.retailerContext;
  }

  @action
  setSearch = (search: string) => {
    this._query = search;
    this.brandContext = undefined;
    this.retailerContext = undefined;
  };

  @computed
  get orderedConversations(): ConversationModel[] {
    // TEST(Ian): search results are used when search or context token exists
    let _orderedConversations: ConversationModel[] =
      this.allOrderedConversations;
    if (this.searchResults) {
      _orderedConversations = compact(
        Array.from(this.searchResults.values()).map((token) =>
          this.conversationByUserToken.get(token)
        )
      );
    }

    //filtering out support conversations as well as the staging Olivia conversation that causes issues https://fairewholesale.atlassian.net/browse/FD-77926
    _orderedConversations = FILTER_SUPPORT_CONVERSATION
      ? _orderedConversations.filter(
          (item) =>
            !item.isSupportConversation &&
            item.otherParty?.token !== "u_eafa228a"
        )
      : _orderedConversations;

    // Filtering out conversations hidden by user when they mark it as spam
    _orderedConversations = _orderedConversations.filter(
      (conversation) => !this.hiddenConversations.includes(conversation.token)
    );
    return _orderedConversations;
  }

  @computed
  get allOrderedConversations(): ConversationModel[] {
    return uniqBy(
      compact(Array.from(this.conversationByUserToken.values())),
      (conversation) => conversation.token
    ).sort((conversationA, conversationB) => {
      // support conversation comes first
      if (
        conversationA.isSupportConversation !==
        conversationB.isSupportConversation
      ) {
        return conversationA.isSupportConversation ? -1 : 1;
      }

      const retailerContextComparator = conversationRetailerContextComparator(
        conversationA,
        conversationB,
        this.retailerContext?.owner_user_token
      );
      if (retailerContextComparator !== 0) {
        return retailerContextComparator;
      }

      const brandContextComparator = conversationBrandContextComparator(
        conversationA,
        conversationB,
        this.brandContext?.token
      );
      if (brandContextComparator !== 0) {
        return brandContextComparator;
      }

      return conversationLastModifiedComparator(conversationA, conversationB);
    });
  }

  @computed
  get unreadConversationCount(): number {
    return this.totalUnreadConversations ?? 0;
  }

  @computed
  get starredByBrandConversationCount(): number {
    return this.totalStarredByBrandConversations ?? 0;
  }

  @computed
  get showOnlyUnreadConversations(): boolean {
    return this._showOnlyUnreadConversations;
  }

  setShowOnlyUnreadConversations = (showOnlyUnreadConversations: boolean) => {
    this._showOnlyUnreadConversations = showOnlyUnreadConversations;
    this.unreadOnlyMainStreamFetchState.removeReadConversations();
    return this.firstFetchOrUpdateConversations();
  };

  @computed
  get showOnlyStarredByBrandConversations(): boolean {
    return this._showOnlyStarredByBrandConversations;
  }

  setShowOnlyStarredByBrandConversations = (
    showOnlyStarredByBrandConversations: boolean
  ) => {
    this._showOnlyStarredByBrandConversations =
      showOnlyStarredByBrandConversations;
    this.starredByBrandOnlyMainStreamFetchState.removeNonStarredByBrandConversations();
    return this.firstFetchOrUpdateConversations();
  };

  get hasConversations(): boolean {
    return this.conversationByUserToken.size > 0;
  }

  @computed
  get latestUnreadConversation(): ConversationModel | undefined {
    for (const conversation of this.orderedConversations) {
      if (conversation.isUnRead) {
        return conversation;
      }
    }
    return;
  }

  isCurrentConversation = (conversationToken?: string): boolean => {
    return (
      !!conversationToken &&
      !!this.currentConversation &&
      this.currentConversation.token === conversationToken
    );
  };

  /*
   * Async
   *
   * Keeping persistent data that doesn't have an ordering with unlimited scroll is very difficult.
   * - needs to be persistent because
   *   - we don't know if the user is looking at it (scroll)
   * - order of conversation is constantly changing based on which ones have the newest messages
   */

  /**
   * When messenger mounts for the first time this populate conversations and nextRequest for scroll
   * Future calls makes sure all conversations are up to date
   *
   * TODO: should this be debounced? first call does something but subsequent calls do nothing until 200ms later?
   */
  firstFetchOrUpdateConversations = async () => {
    if (this.mainStreamFetchState.isFirstFetch) {
      this.mainStreamFetchState.isFirstFetch = false;
      return this.fetchConversations(undefined, true);
    } else {
      // TODO: keep fetching conversations until we find an unchanged conversation
      //   currently this fails if more than 1 page of conversations has changed
      //   requires refactoring fetchConversations and update conversation to return data about what happens
      return this.fetchConversations();
    }
  };

  @computed
  get conversationsNextRequest() {
    return this.mainStreamFetchState.nextRequest;
  }

  @observable
  private totalUnreadConversations?: number;

  @observable
  private totalStarredByBrandConversations?: number;

  @action setTotalUnreadConversations = (
    totalUnreadConversations: number | undefined
  ) => {
    if (totalUnreadConversations !== undefined) {
      this.totalUnreadConversations = totalUnreadConversations;
    }
  };

  @action setTotalStarredByBrandConversations = (
    totalStarredByBrandConversations: number | undefined
  ) => {
    if (totalStarredByBrandConversations !== undefined) {
      this.totalStarredByBrandConversations = totalStarredByBrandConversations;
    }
  };

  nextConversations = throttlePromise(async () => {
    if (this.conversationsNextRequest) {
      return this.fetchConversations(this.conversationsNextRequest, true);
    }
  });

  private searchConversations = async (
    params: Pick<IGetMessengerConversationsRequest, "context_token" | "query">
  ) => {
    await this.fetchConversations(params, false, true);
  };

  /**
   * handles 4 types of fetches
   * - main stream (first fetch, set nextRequest, follow on using nextRequest)
   * - search with context token or query (keep ordering)
   * - get data for a specific conversation if not currently available
   * - update data (doesn't keep ordering or nextRequest)
   */
  private fetchConversations = async (
    data: Partial<IGetMessengerConversationsRequest> = {},
    isMainStreamFetch?: boolean,
    isSearch?: boolean
  ): Promise<void> => {
    try {
      this._isLoadingCount++;
      const {
        conversations,
        total_unread_conversations,
        total_starred_by_brand_conversations,
        simple_brands,
        simple_retailers,
        next_request,
      } = await findMessengerConversations(
        IGetMessengerConversationsRequest.build({
          ...data,
          only_unread:
            data.only_unread || this.showOnlyUnreadConversations || undefined,
          filter: this.showOnlyStarredByBrandConversations
            ? IGetMessengerConversationsRequest.Filter.STARRED_BY_BRAND
            : undefined,
        })
      );

      if (!conversations || !simple_brands || !simple_retailers) {
        logError(
          new Error(
            "This request should return conversations with information about the brands and retailers"
          )
        );
        return;
      }

      runInAction(() => {
        if (isSearch) {
          this.searchResults = compact(
            conversations.map(
              (conversation) =>
                conversation.other_party && conversation.other_party.token
            )
          ).reduce((set, token) => set.add(token), new Set<string>());
        } else if (!this._query && !this.brandContext) {
          this.searchResults = undefined;
        }
        if (isMainStreamFetch) {
          // TODO HACK: this api keeps returning the last set of conversations forever
          const conversationTokens = conversations.map(
            (conversation) => conversation.token
          );
          if (
            isEqual(
              this.mainStreamFetchState.lastMainStreamConversationRequestTokens,
              conversationTokens
            )
          ) {
            this.mainStreamFetchState.nextRequest = undefined;
            return;
          }

          this.mainStreamFetchState.lastMainStreamConversationRequestTokens =
            conversationTokens;
          // TODO End HACK

          this.mainStreamFetchState.nextRequest = next_request;

          this.totalUnreadConversations = total_unread_conversations;
          this.totalStarredByBrandConversations =
            total_starred_by_brand_conversations;
        }

        if (this.brandContext && !simple_brands[this.brandContext.token!]) {
          simple_brands[this.brandContext.token!] = this.brandContext;
        }

        this.createOrUpdateConversations(
          conversations,
          simple_brands,
          simple_retailers
        );
      });
    } catch (e) {
      // TODO: handle error - in the existing implementation this is ignored
      logError(e);
    } finally {
      this._isLoadingCount--;
    }
  };

  /*
    Messenger Service + Umbrella Brands Migration Setting
  */
  private fetchConversationByToken = async (
    conversationToken?: string,
    retailerToken?: string,
    brandToken?: string
  ): Promise<void> => {
    try {
      this._isLoadingCount++;

      let response: IGetConversationResponseV3;

      if (conversationToken) {
        response = await fetchMessengerConversation(conversationToken);
      } else if (this.user?.type === IUser.Type.BRAND_USER && retailerToken) {
        response = await fetchMessengerConversationByRetailer(retailerToken);
      } else if (this.user?.type === IUser.Type.USER && brandToken) {
        response = await fetchMessengerConversationByBrand(brandToken);
      } else {
        logError(
          new Error(
            "This function should recieve a conversation_token, or appropriate [brand | retailer]_token relative to the user"
          )
        );
        return;
      }

      const { conversation, simple_brand, simple_retailer } = response;

      if (!conversation || (!simple_retailer && !simple_brand)) {
        logError(
          new Error(
            "This request should return a conversation and information about the retailer or the brand"
          )
        );
        return;
      }

      runInAction(() => {
        if (!this._query && !this.brandContext) {
          this.searchResults = undefined;
        }

        if (conversation) {
          this.createOrUpdateConversation(
            conversation,
            simple_brand,
            simple_retailer
          );
        }
      });
    } catch (e) {
      logError(e);
    } finally {
      this._isLoadingCount--;
    }
  };

  getSupportConversation = () => {
    const supportConversation = this.getSupportConversationIfExists();
    if (supportConversation !== undefined) {
      return supportConversation;
    } else {
      throw new Error("A support conversation should always exist");
    }
  };

  getSupportConversationIfExists = () => {
    for (const conversation of Array.from(
      this.conversationByUserToken.values()
    )) {
      if (conversation.type === MessengerConversationType.SUPPORT) {
        return conversation;
      }
    }
    return undefined;
  };

  setCurrentConversation = async (
    param: {
      otherPartyUserToken?: string;
      brandToken?: string;
      retailerToken?: string;
      conversationToken?: string;
    } = {}
  ): Promise<void> => {
    const {
      otherPartyUserToken,
      brandToken,
      retailerToken,
      conversationToken,
    } = param;

    if (
      !conversationToken &&
      !otherPartyUserToken &&
      !brandToken &&
      !retailerToken
    ) {
      this._currentConversation = undefined;
      return;
    }

    if (
      this.attemptToFindAndSetConversation(
        conversationToken,
        brandToken,
        retailerToken,
        otherPartyUserToken
      )
    ) {
      return;
    }
    await this.fetchConversationByToken(
      conversationToken,
      retailerToken,
      brandToken
    );
    if (
      !this.attemptToFindAndSetConversation(
        conversationToken,
        brandToken,
        retailerToken,
        otherPartyUserToken
      )
    ) {
      throw new Error(
        "A conversation should have been returned by the server."
      );
    }
  };

  @computed
  get latestUnreadPopUpConversation(): ConversationModel | undefined {
    const popUpConversations = Array.from(this.conversationByUserToken.values())
      .filter(
        (value) =>
          value.latestMessage &&
          value.latestMessage.shouldPopUp &&
          !value.latestMessage.isRead
      )
      .sort(conversationLastModifiedComparator);
    if (popUpConversations.length > 0) {
      return popUpConversations[0];
    }
    return undefined;
  }

  private attemptToFindAndSetConversation = (
    conversationToken?: string,
    brandToken?: string,
    retailerToken?: string,
    otherPartyUserToken?: string
  ): boolean => {
    let conversation: ConversationModel | undefined;

    // TODO: change the conversationByXToken functions to point to a reference of a master list of conversations
    if (
      conversationToken &&
      this.conversationByConversationToken.get(conversationToken)
    ) {
      conversation =
        this.conversationByConversationToken.get(conversationToken);
    } else {
      // Since we're only getting brand or retailer tokens, we have to use them to do the initial look-up
      if (brandToken && this.conversationByBrandToken.get(brandToken)) {
        conversation = this.conversationByBrandToken.get(brandToken);
      } else if (
        retailerToken &&
        this.conversationByRetailerToken.get(retailerToken)
      ) {
        conversation = this.conversationByRetailerToken.get(retailerToken);
      } else if (
        otherPartyUserToken &&
        this.conversationByUserToken.get(otherPartyUserToken)
      ) {
        conversation = this.conversationByUserToken.get(otherPartyUserToken);
      }

      // If we found a real conversation, let's pull it from the conversation_token indexed list for consistency
      if (
        conversation &&
        conversation.token &&
        this.conversationByConversationToken.get(conversation.token)
      ) {
        conversation = this.conversationByConversationToken.get(
          conversation.token
        );
      }
    }

    if (conversation) {
      this._currentConversation = conversation;
      return true;
    }
    return false;
  };

  isConversationToken = (token: string): boolean => {
    return token.startsWith("mc_");
  };

  setConversationIsStarredByBrand = async (
    conversationToken: string,
    isStarred: boolean
  ) => {
    if (isStarred) {
      this.setTotalStarredByBrandConversations(
        this.totalStarredByBrandConversations
          ? this.totalStarredByBrandConversations + 1
          : 1
      );
    } else {
      this.setTotalStarredByBrandConversations(
        this.totalStarredByBrandConversations
          ? this.totalStarredByBrandConversations - 1
          : 0
      );
    }

    // Immediately updates star icon locally
    this.getConversationByConversationToken(
      conversationToken
    )?.setIsStarredByBrand(isStarred);

    // Submit the endpoint to actually update the conversation, and
    // re-fetch the conversations, to align with backend state.
    await postStarConversation({
      conversation_token: conversationToken,
      star: isStarred,
    });
    await this.firstFetchOrUpdateConversations();
  };

  toggleConversationIsStarredByBrand = async (conversationToken: string) => {
    const conversation =
      this.getConversationByConversationToken(conversationToken);
    if (!conversation) {
      throw new Error(`conversation not found: ${conversationToken}`);
    }

    await this.setConversationIsStarredByBrand(
      conversationToken,
      !conversation.isStarredByBrand
    );
  };

  private createOrUpdateConversations = (
    conversations: IMessengerConversation[],
    simple_brands: Partial<
      Record<string, IGetMessengerConversationsResponse.ISimpleBrand>
    >,
    simple_retailers: Partial<
      Record<string, IGetMessengerConversationsResponse.ISimpleRetailer>
    >
  ) => {
    for (const conversation of conversations) {
      if (!conversation.other_party) {
        throw new Error("An unknown error occurred");
      }
      const brand = simple_brands[conversation.brand_token!];
      const retailer = simple_retailers[conversation.retailer_token!];

      this.createOrUpdateConversation(conversation, brand, retailer);
    }
  };

  private createOrUpdateConversation = (
    conversation: IMessengerConversation,
    simple_brand?: IGetMessengerConversationsResponse.ISimpleBrand,
    simple_retailer?: IGetMessengerConversationsResponse.ISimpleRetailer
  ) => {
    const otherUser = conversation.other_party;
    const otherIsBrandUser =
      otherUser && otherUser.type === IUser.Type.BRAND_USER;
    const otherIsRetailerUser = otherUser && otherUser.type === IUser.Type.USER;
    const otherPartyUserToken =
      conversation.other_party && conversation.other_party.token;

    // Multi-brand allows users to be linked to multiple brands and switch between them.
    // As a result, a WebSocket channel may have been initiated and connected by a different brand.
    // Therefore, when receiving a message from the WebSocket, we need to verify if the message is intended for the current brand.
    // More details in https://www.notion.so/faire/Test-Spec-Multi-Brand-1272efb5c25a80bd853dcc6bf66f9791?pvs=4#1512efb5c25a80299108eb4fd5453f5b
    const currentBrandToken = getBrandTokenInSession();
    const isMessageForAnotherBrand =
      otherIsRetailerUser && conversation.brand_token !== currentBrandToken;

    if (!otherUser || !otherPartyUserToken || isMessageForAnotherBrand) {
      return;
    }

    let updatedConversation =
      this.searchForConversationInFetchStreams(conversation);

    if (updatedConversation) {
      updatedConversation.updateConversation(
        conversation,
        simple_brand,
        simple_retailer
      );
    } else {
      updatedConversation = new ConversationModel(
        this,
        conversation,
        this.strictLocalize,
        this.user,
        simple_brand,
        simple_retailer
      );
    }

    this.conversationByUserToken.set(otherPartyUserToken, updatedConversation);

    if (updatedConversation.token) {
      this.conversationByConversationToken.set(
        updatedConversation.token,
        updatedConversation
      );
    }

    if (otherIsBrandUser && otherUser && conversation.brand_token) {
      this.conversationByBrandToken.set(
        conversation.brand_token,
        updatedConversation
      );
    } else if (otherIsRetailerUser && otherUser && otherUser.retailer_token) {
      this.conversationByRetailerToken.set(
        otherUser.retailer_token,
        updatedConversation
      );
    }
    // If the latest message is from the other user and the other user is a brand user, it's a message from the brand to the retailer
    if (
      conversation.latest_message?.author_token === otherUser.token &&
      otherIsBrandUser &&
      this.latestMessageUpdatedAt !== conversation.latest_message?.updated_at &&
      conversation.latest_message?.text !== "review request"
    ) {
      trackMessengerMessageFromBrandToRetailer(
        conversation?.retailer_token ?? "",
        conversation.brand_token ?? "",
        conversation.latest_message?.updated_at?.toString() ?? "",
        conversation.latest_message?.text ?? ""
      );
      this.latestMessageUpdatedAt = conversation.latest_message?.updated_at;
    }

    if (
      conversation.latest_message?.author_token === otherUser.token &&
      otherIsRetailerUser &&
      this.latestMessageUpdatedAt !== conversation.latest_message?.updated_at &&
      conversation.latest_message?.text !== "review request"
    ) {
      trackMessengerMessageFromRetailerToBrand(
        "",
        conversation.brand_token ?? "",
        conversation?.retailer_token ?? "",
        conversation.latest_message?.updated_at?.toString() ?? "",
        conversation.latest_message?.text ?? "",
        ""
      );
    }
  };

  private searchForConversationInFetchStreams = (
    conversation: IMessengerConversation
  ): ConversationModel | undefined => {
    const otherUser = conversation.other_party;
    const otherIsBrandUser =
      otherUser && otherUser.type === IUser.Type.BRAND_USER;
    const otherIsRetailerUser = otherUser && otherUser.type === IUser.Type.USER;
    const otherPartyUserToken =
      conversation.other_party && conversation.other_party.token;

    if (!otherUser || !otherPartyUserToken) {
      return;
    }

    let updatedConversation: ConversationModel | undefined;
    if (conversation.token) {
      updatedConversation =
        this.allMainStreamFetchState.conversationByConversationToken.get(
          conversation.token
        ) ??
        this.unreadOnlyMainStreamFetchState.conversationByConversationToken.get(
          conversation.token
        ) ??
        this.starredByBrandOnlyMainStreamFetchState.conversationByConversationToken.get(
          conversation.token
        );
    } else {
      if (otherIsBrandUser && conversation.brand_token) {
        updatedConversation =
          this.allMainStreamFetchState.conversationByBrandToken.get(
            conversation.brand_token
          ) ??
          this.unreadOnlyMainStreamFetchState.conversationByBrandToken.get(
            conversation.brand_token
          ) ??
          this.starredByBrandOnlyMainStreamFetchState.conversationByBrandToken.get(
            conversation.brand_token
          );
      } else if (otherIsRetailerUser && otherUser.retailer_token) {
        updatedConversation =
          this.allMainStreamFetchState.conversationByRetailerToken.get(
            otherUser.retailer_token
          ) ??
          this.unreadOnlyMainStreamFetchState.conversationByRetailerToken.get(
            otherUser.retailer_token
          ) ??
          this.starredByBrandOnlyMainStreamFetchState.conversationByRetailerToken.get(
            otherUser.retailer_token
          );
      }
    }

    return updatedConversation;
  };

  private handleConnectionMessage = (isConnected: boolean) => {
    logDebug(`handleConnectionMessage: ${isConnected}`);
    if (!isConnected) {
      if (!this.pollingFallback) {
        logDebug(`Fallback polling started`);
        this.pollingFallback = new MessengerPollingFallback(() => {
          logDebug(`Polling found a new message`);
          this.firstFetchOrUpdateConversations();
          if (this._currentConversation) {
            this._currentConversation.firstFetchOrUpdateMessages();
          }
        }, this.wasConnected);
      }
    } else {
      this.wasConnected = true;
      if (this.pollingFallback) {
        logDebug(`Fallback polling stopped`);
        this.pollingFallback.stop();
        this.pollingFallback = undefined;
      }
    }
  };

  @action
  private handleNewMessage = (message: Message) => {
    if (
      message.type === IWebSocketMessage.Type.CONVERSATION_UPDATED &&
      message.updated_conversation
    ) {
      this.createOrUpdateConversation(message.updated_conversation);
    }

    if (message.type === "CONNECTION") {
      this.handleConnectionMessage(message.isConnected);
    }
  };

  @action
  hideConversation = (conversationToken: string) => {
    this.hiddenConversations.push(conversationToken);
  };
}
