import { request } from '@faire/web-api--source';
import { IGetMessengerConversationsRequest } from '@faire/web-api--source/indigofair/data/IGetMessengerConversationsRequest';
import { IGetMessengerConversationsResponse } from '@faire/web-api--source/indigofair/data/IGetMessengerConversationsResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/messenger/list-conversations`;
};

export const build = (
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messenger/list-conversations';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(
  data: IGetMessengerConversationsRequest
): Promise<IGetMessengerConversationsResponse>;
function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IGetMessengerConversationsResponse>>;
function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): Promise<IGetMessengerConversationsResponse>;
function execute(
  data: IGetMessengerConversationsRequest,
  options?: EndpointOptions
): Promise<
  | IGetMessengerConversationsResponse
  | SuccessResponse<IGetMessengerConversationsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<IGetMessengerConversationsResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/messenger/list-conversations/post';
export default execute;
