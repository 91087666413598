import { findItemByOptionTokenAndCustomizations } from "@faire/retailer-visitor-shared/lib/findItemByOptionTokenAndCustomizations";
import {
  getCartProductOptionQuantitiesMap,
  getCartProductQuantitiesMap,
} from "@faire/retailer-visitor-shared/lib/getCartItemQuantitiesMap";
import { useIsLoggedInRetailer } from "@faire/retailer-visitor-shared/lib/isLoggedInRetailer";
import { buildUpdateCartProto } from "@faire/retailer-visitor-shared/lib/updateCartV2";
import { getCartToken } from "@faire/retailer-visitor-shared/serialized-data/getCartToken";
import {
  ICartItemForUpdate,
  ILeanCartItem,
  ILeanCartItemV2,
} from "@faire/retailer-visitor-shared/stores/domain/CartStore/consts";
import { isNotUndefined } from "@faire/web--source/common/typescriptUtils";
import { QueryParameters as LeanBrandsMapParams } from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/brands/get";
import {
  useQuery as useLeanBrandsMapQuery,
  useInvalidateQuery as useInvalidateLeanBrandsMapQuery,
} from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/brands/get-hooks";
import { QueryParameters as CartItemInventoryParams } from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/item-inventory/get";
import {
  useQuery as useCartItemInventoryQuery,
  useInvalidateQuery as useInvalidateCartItemInventoryQuery,
} from "@faire/web-api--source/endpoints/www/api/v2/carts/cartToken/item-inventory/get-hooks";
import { ICartItemV2 } from "@faire/web-api--source/faire/carts/ICartItemV2";
import { IItemInventoryResponse } from "@faire/web-api--source/faire/carts/IItemInventoryResponse";
import { useCallback, useMemo } from "react";

type Arg = {
  inventoryParams?: CartItemInventoryParams;
  leanBrandsMapParams?: LeanBrandsMapParams;
};

export const useCartItems = (arg?: Arg) => {
  const isLoggedInRetailer = useIsLoggedInRetailer();

  const { data: cartInventoryData, isPending: isLoading } =
    useCartItemInventoryQuery(
      getCartToken(),
      arg?.inventoryParams ?? CartItemInventoryParams.build({}),
      { enabled: isLoggedInRetailer, refetchOnMount: false }
    );

  const { data: leanBrandsData } = useLeanBrandsMapQuery(
    getCartToken(),
    arg?.leanBrandsMapParams,
    { enabled: isLoggedInRetailer, refetchOnMount: false }
  );

  const leanCartItemMap = cartInventoryData?.cart_item_token_to_inventory_item;
  const totalBrandsInCartFormatted =
    cartInventoryData?.total_brands_in_cart_formatted;

  const leanBrandsMap = leanBrandsData?.brand_info;
  const leanBrands = useMemo(
    () => Object.values(leanBrandsMap ?? {}).filter(isNotUndefined),
    [leanBrandsMap]
  );

  const cartItems: IItemInventoryResponse.IInventoryItem[] = useMemo(
    () => Object.values(leanCartItemMap ?? {}).filter(isNotUndefined),
    [leanCartItemMap]
  );

  const itemQuantityInCartByToken: Record<string, number> = useMemo(
    () => getCartProductOptionQuantitiesMap(cartItems),
    [cartItems]
  );

  const productTileQuantityInCartByToken: Record<string, number> = useMemo(
    () => getCartProductQuantitiesMap(cartItems),
    [cartItems]
  );

  const getItemByToken = useCallback(
    (cartItemToken: string): ILeanCartItem | undefined => {
      return leanCartItemMap?.[cartItemToken];
    },
    [leanCartItemMap]
  );

  const getItemQuantityInCart = useCallback(
    (productOptionToken: string): number => {
      return itemQuantityInCartByToken[productOptionToken] ?? 0;
    },
    [itemQuantityInCartByToken]
  );

  const getProductTileQuantityInCart = useCallback(
    (productToken: string): number => {
      return productTileQuantityInCartByToken[productToken] ?? 0;
    },
    [productTileQuantityInCartByToken]
  );

  const getItemByProductOptionTokenAndCustomizations = useCallback(
    (
      token: string,
      customizations: Map<string, string>
    ): ILeanCartItem | undefined => {
      return findItemByOptionTokenAndCustomizations(
        token,
        customizations,
        cartItems
      );
    },
    [cartItems]
  );

  const isBrandInCart = (brandToken: string) => {
    return isNotUndefined(leanBrandsMap?.[brandToken]);
  };

  const createUpdateCartItemWithProperties = useCallback(
    (
      item: ILeanCartItemV2,
      properties: Partial<ICartItemForUpdate>
    ): ICartItemForUpdate[] => {
      if (item.type === ICartItemV2.Type.OPEN_SIZING) {
        const items: ICartItemForUpdate[] = [];
        const cartOptionTokens = [
          ...item.associated_cart_item_tokens,
          item.token ?? "",
        ];
        cartOptionTokens?.forEach((token: string) => {
          const itemInStore = getItemByToken(token ?? "");
          if (!itemInStore) {
            return;
          }
          items.push(
            ICartItemForUpdate.build({
              token: token,
              brand_token: item.brand_token,
              product_token: itemInStore.product_token,
              product_option_token: itemInStore.product_option_token,
              quantity: itemInStore.quantity,
              customizations: itemInStore.customizations ?? [],
              ...properties,
            })
          );
        });
        return items;
      }
      return [buildUpdateCartProto(item, properties, false)];
    },
    [getItemByToken]
  );

  return {
    leanCartItemMap,
    totalBrandsInCartFormatted,
    leanBrandsMap,
    leanBrands,
    cartItems,
    getItemByToken,
    getItemQuantityInCart,
    getProductTileQuantityInCart,
    getItemByProductOptionTokenAndCustomizations,
    isBrandInCart,
    createUpdateCartItemWithProperties,
    isLoading,
  };
};

export const useInvalidateCartItems = (arg?: Arg) => {
  const invalidateCartItemInventoryQuery =
    useInvalidateCartItemInventoryQuery();
  const invalidateLeanBrandsMapQuery = useInvalidateLeanBrandsMapQuery();

  const invalidateCartItemInventory = useCallback(
    () =>
      invalidateCartItemInventoryQuery(
        getCartToken(),
        arg?.inventoryParams ?? CartItemInventoryParams.build({}),
        undefined,
        { refetchType: "all" }
      ),
    [arg?.inventoryParams, invalidateCartItemInventoryQuery]
  );

  const invalidateLeanBrandsMap = useCallback(
    () =>
      invalidateLeanBrandsMapQuery(
        getCartToken(),
        arg?.leanBrandsMapParams,
        undefined,
        { refetchType: "all" }
      ),
    [arg?.leanBrandsMapParams, invalidateLeanBrandsMapQuery]
  );

  return {
    invalidateCartItemInventory,
    invalidateLeanBrandsMap,
  };
};
