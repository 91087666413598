import { request } from '@faire/web-api--source';
import { IDeleteMessageResponse } from '@faire/web-api--source/indigofair/data/IDeleteMessageResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = (messageToken: string) => {
  return `/api/messenger/${messageToken}`;
};

export const build = (
  messageToken: string,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(messageToken),
    method: 'DELETE',
    ...options,
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/messenger/:messageToken';

/**
 * Product Area: MESSENGER_INFRASTRUCTURE
 */
function execute(messageToken: string): Promise<IDeleteMessageResponse>;
function execute(
  messageToken: string,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<IDeleteMessageResponse>>;
function execute(
  messageToken: string,
  options?: EndpointOptions
): Promise<IDeleteMessageResponse>;
function execute(
  messageToken: string,
  options?: EndpointOptions
): Promise<IDeleteMessageResponse | SuccessResponse<IDeleteMessageResponse>> {
  const { route, ...requestOptions } = build(messageToken, options);
  return request<IDeleteMessageResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/messenger/messageToken/delete';
export default execute;
